import React from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import googleIcon from "../images/google-icon.png";
import { signInWithGoogle } from "../auth";

const Login = () => {
  const navigate = useNavigate();

  const handleSignIn = async () => {
    const result = await signInWithGoogle();
    if (result) {
      // Sign-in successful.
      // Navigate to dashboard or another protected route.
      navigate("/download");
    } else {
      // Handle sign-in failure if needed
      alert("Sign-in failed. Please try again.");
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Navbar />
      <div className="w-users-userformpagewrap page-wrapper">
        <div className="w-users-userloginformwrapper login-form-wrapper">
          <h4>Log in to your account</h4>
          <p>Welcome back! Please enter your details.</p>

          {/* Log In With Google Button */}
          <button type="button" className="w-users-userformbutton button w-button" onClick={handleSignIn}>
            <img src={googleIcon} alt="Google Icon" className="icon" />
            Log In With Google
          </button>

          <span className="separator">- or -</span>

          {/* Sign Up With Google Button */}
          <button type="button" className="w-users-userformbutton button secondary w-button" onClick={handleSignIn}>
            <img src={googleIcon} alt="Google Icon" className="icon" />
            Sign Up With Google
          </button>

          {/* Horizontal Rule */}
          <hr
            style={{
              border: "none",
              borderTop: "1px solid #9090909a",
              margin: "16px 0",
              width: "100%",
              marginTop: "40px",
            }}
          />

          {/* Go Back Button */}
          <button className="w-users-userformbutton button secondary w-button back-button" onClick={handleGoBack}>
            Go Back
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
