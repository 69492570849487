import React from "react";

const NotFound = () => {
  return (
    <div className="utility-page-wrap">
      <div className="login-form-wrapper red">
        <div className="_404">
          <div className="_404-title">
            <h2 className="title">404 Opps!</h2>
            <p>Well well well, it looks like you stumbled upon a 404 error! Don&#x27;t worry, it&#x27;s not your fault - the page you&#x27;re looking for must have gone on a wild adventure and gotten lost somewhere.</p>
          </div>
          <a href="/" className="button w-button">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
