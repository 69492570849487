import React from "react";

const Failure = () => {
  return (
    <div className="utility-page-wrap">
      <div className="login-form-wrapper red">
        <div className="_404">
          <div className="_404-title">
            <h2 className="title">Something went wrong!</h2>
            <p>Oops! Something went wrong with your payment. It seems the transaction couldn’t be completed. Please check your payment details and try again. If the issue persists, feel free to contact our support team for assistance.</p>{" "}
          </div>
          <a href="/" className="button w-button">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Failure;
