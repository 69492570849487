import React from "react";

const Cancel = () => {
  return (
    <div className="utility-page-wrap">
      <div className="login-form-wrapper red">
        <div className="_404">
          <div className="_404-title">
            <h2 className="title">Sorry to see you go!</h2>
            <p>Your subscription has been successfully canceled. We're sad to see you leave, but we hope to have you back in the future! If you have any questions or need assistance, please don't hesitate to contact our support team.</p>
          </div>
          <a href="/" className="button w-button">
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Cancel;
