import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase"; // Adjust the path if necessary
import logoImage from "../images/logo_1.png"; // Adjust the path as necessary

function Navbar() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
      <div className="container nav-container-wrapper">
        <div className="navbar-holder">
          <div className="navbar-container">
            {/* Brand Logo and Name */}
            <div className="brand-holder">
              <Link to="/" className="brand w-nav-brand">
                <div className="w-layout-blockcontainer container-2 w-container">
                  <img src={logoImage} loading="lazy" width="30" height="30" alt="Edutools Logo" className="brand-image" />
                  <h5 className="heading" style={{ color: "white" }}>
                    Edutools
                  </h5>
                </div>
              </Link>
            </div>
            {/* Navigation Links */}
            <div className="navbar-link">
              <nav role="navigation" className="nav-menu w-nav-menu">
                <div className="nav-menu-link-holder">
                  <div className="nav-menu-link-container">
                    <div className="nav-links">
                      <Link to="/" className="nav-link w-nav-link">
                        Home
                      </Link>
                      <HashLink to="/#trusted-by-id" className="nav-link w-nav-link">
                        Features
                      </HashLink>
                      <HashLink to="/#review-section" className="nav-link w-nav-link">
                        Reviews
                      </HashLink>
                      <HashLink to="/#pricing-section" className="nav-link w-nav-link">
                        Pricing
                      </HashLink>
                      <HashLink to="/#faq-section" className="nav-link w-nav-link">
                        FAQ
                      </HashLink>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            {/* Authentication Button */}
            <div className="nav-button-holder">
              {user ? (
                <button className="button secondary w-button" id="auth-button" onClick={handleSignOut}>
                  Log Out
                </button>
              ) : (
                <Link to="/login" className="button secondary w-button" id="auth-button">
                  Sign Up
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
