import React from "react";
import Navbar from "../components/Navbar";
import appleIcon from "../images/apple.png";
import windowsIcon from "../images/window.png";

const DownloadApp = () => {
  return (
    <>
      <Navbar />
      {/* Main Section */}
      <div className="section">
        <div className="container">
          <div className="hero-section-center-holder">
            {/* Heading */}
            <div className="animate-on-load-01">
              <div className="hero-center-text">
                <h1 className="title">Download Edutools</h1>
              </div>
            </div>

            {/* Subheading */}
            <div className="animate-on-load-02">
              <div className="center-hero-paragraph-holder">
                <p>Get started by downloading the app for your platform.</p>
              </div>
            </div>

            {/* Download Boxes */}
            <div className="animate-on-load-03">
              <div className="pricing-wrapper">
                <div className="pricing-details-holder" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                  {/* Mac Download Box */}
                  <div className="content" style={{ textAlign: "center" }}>
                    <div className="pricing">
                      <div
                        className="download-box"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={appleIcon} alt="Mac Icon" className="download-icon" style={{ width: "50px", height: "50px" }} />
                        <h3 className="download-heading">Download for Mac</h3>
                        <a href="https://your-mac-download-link.com" className="button" style={{ marginTop: "10px" }}>
                          Download
                        </a>
                        <p className="beta-text" style={{ marginTop: "10px", color: "grey", fontSize: "0.9rem" }}>
                          Beta v0.1
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Windows Download Box */}
                  <div className="content" style={{ textAlign: "center" }}>
                    <div className="pricing">
                      <div
                        className="download-box"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img src={windowsIcon} alt="Windows Icon" className="download-icon" style={{ width: "50px", height: "50px" }} />
                        <h3 className="download-heading">Download for Windows</h3>
                        <a href="https://github.com/TheWilsonDev/edutools-download/releases/download/v0.1/dist.rar" className="button" style={{ marginTop: "10px" }}>
                          Download
                        </a>
                        <p className="beta-text" style={{ marginTop: "10px", color: "grey", fontSize: "0.9rem" }}>
                          Beta v0.1
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-on-load-04" style={{ marginTop: "40px" }}>
              <div className="center-hero-paragraph-holder">
                <a href="/download" className="center-hero-link">
                  or upgrade your account
                </a>
              </div>
            </div>

            {/* Additional content, if any */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
