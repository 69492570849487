import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

const Download = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [showOrderSection, setShowOrderSection] = useState(false);
  const [showPaidSection, setShowPaidSection] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Monthly");
  const [priceValue, setPriceValue] = useState("$19.99");

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  useEffect(() => {
    console.log("User Data:", userData);
  }, [userData]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        navigate("/login");
        return;
      }

      setUser(currentUser);

      const userId = currentUser.uid;
      const userDocRef = doc(db, "users", userId);

      try {
        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists()) {
          const newUser = {
            blocks: [],
            dateJoined: new Date().toISOString(),
            displayName: currentUser.displayName || currentUser.email,
            email: currentUser.email,
            friendRequests: [],
            friends: [],
            photoURL: currentUser.photoURL || "",
            tags: [],
            paymentStatus: "free",
          };

          await setDoc(userDocRef, newUser);
          console.log("New user created:", newUser);
          setUserData(newUser);
        } else {
          setUserData(userDoc.data());
        }

        const paymentStatus = userDoc.data()?.paymentStatus;

        if (paymentStatus === "paid" || paymentStatus === "semester") {
          setShowPaidSection(true);
          setShowOrderSection(false);
        } else {
          setShowPaidSection(false);
          setShowOrderSection(true);
        }
      } catch (error) {
        console.error("Error checking/creating user document:", error);
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handlePlanChange = (event) => {
    const plan = event.target.value;
    setSelectedPlan(plan);

    if (plan === "Monthly") {
      setPriceValue("$19.99 / month");
    } else if (plan === "Semesterly") {
      setPriceValue("$49.99 / 4 months");
    }
  };

  const handleCheckout = async () => {
    const priceMap = {
      Monthly: "price_1QPIq9CKDtg3cmb0lE7mHMEM",
      Semesterly: "price_1QPIqvCKDtg3cmb0wvNFBXEX",
    };

    const priceId = priceMap[selectedPlan];

    if (!priceId) {
      alert("Invalid plan selected.");
      return;
    }

    try {
      const response = await fetch("/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
          email: user.email,
          uid: user.uid,
          plan: selectedPlan,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const { sessionId } = await response.json();

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error.message);
        alert("An error occurred during checkout. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("The checkout service is currently unavailable. Please try again later.");
    }
  };

  const handleCancelSubscription = async () => {
    if (!user || !user.uid) {
      alert("User not authenticated.");
      return;
    }

    try {
      // Get the subscriptionId from user data
      const subscriptionId = userData.subscriptionId;

      if (!subscriptionId) {
        alert("No active subscription found.");
        return;
      }

      const cancelSubscription = httpsCallable(functions, "cancelSubscription");

      const result = await cancelSubscription({
        subscriptionId: subscriptionId,
        uid: user.uid,
      });

      if (result.data.success) {
        alert("Subscription canceled successfully.");
        // Update UI
        setShowPaidSection(false);
        setShowOrderSection(true);
        // Optionally, refresh user data
        // Fetch the updated user data from Firestore
        const userDocRef = doc(db, "users", user.uid);
        const updatedUserDoc = await getDoc(userDocRef);
        setUserData(updatedUserDoc.data());
      } else {
        alert("Failed to cancel subscription.");
      }
    } catch (error) {
      console.error("Error canceling subscription:", error);
      alert("An error occurred while canceling your subscription. Please try again later.");
    }
  };

  return (
    <>
      <Navbar />
      {showOrderSection && (
        <div className="section" id="order-section">
          <div className="container">
            <div className="hero-section-center-holder">
              <div className="animate-on-load-01">
                <div className="hero-center-text">
                  <h1 className="title">Download Edutools</h1>
                </div>
              </div>
              <div className="animate-on-load-02">
                <div className="center-hero-paragraph-holder">
                  <p>Start your journey today! Choose a plan below.</p>
                </div>
              </div>
              <div className="animate-on-load-03">
                <div className="pricing-wrapper">
                  <div className="pricing-details-holder">
                    <div className="content">
                      <div className="pricing">
                        <div className="pricing-header left">
                          <div className="price-plan" id="plan-name">
                            {selectedPlan}
                          </div>
                          <div className="price-amount" id="price-value">
                            {priceValue}
                          </div>
                        </div>
                        <div className="list-holder">
                          <ul className="w-list-unstyled">
                            <li className="list-item">
                              <div className="check-icon-holder">
                                <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                              </div>
                              <div className="check-item-text">Access to all basic features</div>
                            </li>
                            <li className="list-item">
                              <div className="check-icon-holder">
                                <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                              </div>
                              <div className="check-item-text">Access to premium accounts</div>
                            </li>
                            <li className="list-item">
                              <div className="check-icon-holder">
                                <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                              </div>
                              <div className="check-item-text">Access to our in-browser solution</div>
                            </li>
                            <li className="list-item">
                              <div className="check-icon-holder">
                                <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                              </div>
                              <div className="check-item-text">High-speed desktop app</div>
                            </li>
                            <li className="list-item">
                              <div className="check-icon-holder">
                                <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                              </div>
                              <div className="check-item-text">24/7 chat support</div>
                            </li>
                            {selectedPlan === "Semesterly" && (
                              <li className="list-item" id="semester-note">
                                <div className="check-icon-holder">
                                  <img src="https://cdn.prod.website-files.com/6740c522f59d44b6c586dfc0/6740c523f59d44b6c586e05b_Check.svg" loading="lazy" alt="Check Icon" className="check-icon" />
                                </div>
                                <div className="check-item-text">
                                  <strong>3 FREE</strong> months
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="pricing-add-to-cart">
                      <div id="welcome-message" style={{ textAlign: "left", fontSize: "1.5em", marginBottom: "-10px" }}>
                        Welcome, {user ? user.displayName || user.email : ""}
                      </div>
                      <div className="price" id="price-value">
                        {priceValue.split("/")[0]}
                      </div>
                      <div>
                        <div className="text-field-holder">
                          <label className="check-item-text">Your Plan</label>
                          <div>
                            <select id="plan-select" className="text-field dark-text-field w-select" required value={selectedPlan} onChange={handlePlanChange}>
                              <option value="Monthly">Monthly</option>
                              <option value="Semesterly">Semesterly</option>
                            </select>
                          </div>
                        </div>
                        <div className="add-to-card-button-holder">
                          <button id="checkout-button" className="button" onClick={handleCheckout}>
                            Upgrade Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="animate-on-load-04" style={{ marginTop: "40px" }}>
                <div className="center-hero-paragraph-holder">
                  <a href="/download-app" className="center-hero-link">
                    or download the app without upgrading account
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPaidSection && (
        <div className="section" id="paid-section">
          <div className="container">
            <div className="hero-section-center-holder">
              <div className="animate-on-load-01">
                <div className="hero-center-text">
                  <h1 className="title">Download Edutools</h1>
                </div>
              </div>
              <div className="animate-on-load-02">
                <div className="center-hero-paragraph-holder">
                  <p>You already have a subscription!</p>
                </div>
              </div>
              <div className="animate-on-load-03">
                <div className="pricing-wrapper">
                  <div className="pricing-details-holder">
                    <div className="content">
                      <div className="pricing">
                        <div className="pricing-header left">
                          <div className="price-plan" id="subscription-type">
                            Features
                          </div>
                        </div>
                        <div className="list-holder"></div>
                      </div>
                    </div>
                    <div className="pricing-add-to-cart">
                      <div
                        className="welcome"
                        id="welcome-value"
                        style={{
                          fontSize: "2.5rem",
                          fontWeight: "bold",
                          margin: 0,
                          lineHeight: 1.2,
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        Welcome, {user ? user.displayName || user.email : ""}
                      </div>
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <a href="/download-app" className="center-hero-link button w-button" style={{ display: "inline-block" }}>
                          Download the App
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "left", marginTop: "20px" }}>
                <button className="button w-button" style={{ background: "red", color: "white", textDecoration: "none" }} onClick={handleCancelSubscription}>
                  Cancel Subscription
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Download;
